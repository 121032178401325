import { MutationTree } from "vuex";
import { QuoteStates } from "./state";
import {
  AuthRequestStatus,
  CreateUserInfo,
  LGA,
  LoginInfo,
  NigerianState,
  Status,
  UserToken,
} from "@/types";
import SessionStorageService from "@/services/sessionStorage";
import { useRouter } from "vue-router";
import { NextRepayment } from "@/views/Dashboard/types";
import {
  EmployeeLists,
  FinancialInstitutions,
  ProductComponentResponse,
  RepaymentRanges,
  WarrantyDetails,
} from "@/views/Quote/types";

export enum QuoteMutationTypes {
  // CREATE QUOTE
  CREATE_QUOTE_LOADING = "CREATE_QUOTE_LOADING",
  CREATE_QUOTE_SUCCESS = "CREATE_QUOTE_SUCCESS",
  CREATE_QUOTE_ERROR = "CREATE_QUOTE_ERROR",

  // REFRESH QUOTE
  REFRESH_QUOTE_LOADING = "REFRESH_QUOTE_LOADING",
  REFRESH_QUOTE_SUCCESS = "REFRESH_QUOTE_SUCCESS",
  REFRESH_QUOTE_ERROR = "REFRESH_QUOTE_ERROR",

  //UPDATE QUOTE REQUEST
  UPDATE_QUOTE_LOADING = "UPDATE_QUOTE_LOADING",
  UPDATE_QUOTE_SUCCESS = "UPDATE_QUOTE_SUCCESS",
  UPDATE_QUOTE_ERROR = "UPDATE_QUOTE_ERROR",

  // GET QUOTE REQUEST
  GET_QUOTE_LOADING = "GET_QUOTE_LOADING",
  GET_QUOTE_SUCCESS = "GET_QUOTE_SUCCESS",
  GET_QUOTE_ERROR = "GET_QUOTE_ERROR",

  // GET QUOTE REQUEST
  GET_PRODUCTS_LOADING = "GET_PRODUCTS_LOADING",
  GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR",

  // GET ALL STATES
  GET_STATES_LOADING = "GET_STATES_LOADING",
  GET_STATES_SUCCESS = "GET_STATES_SUCCESS",
  GET_STATES_ERROR = "GET_STATES_ERROR",

  // GET STATE LGA
  GET_LGA_LOADING = "GET_LGA_LOADING",
  GET_LGA_SUCCESS = "GET_LGA_SUCCESS",
  GET_LGA_ERROR = "GET_LGA_ERROR",

  // SAVE QUOTE PAYMENT
  SAVE_QUOTE_PAYMENT_LOADING = "SAVE_QUOTE_PAYMENT_LOADING",
  SAVE_QUOTE_PAYMENT_SUCCESS = "SAVE_QUOTE_PAYMENT_SUCCESS",
  SAVE_QUOTE_PAYMENT_ERROR = "SAVE_QUOTE_PAYMENT_ERROR",

  // CONFIRM INSTALLATION DATE
  CONFIRM_FULFILMENT_DATE_LOADING = "CONFIRM_FULFILMENT_DATE_LOADING",
  CONFIRM_FULFILMENT_DATE_SUCCESS = "CONFIRM_FULFILMENT_DATE_SUCCESS",
  CONFIRM_FULFILMENT_DATE_ERROR = "CONFIRM_FULFILMENT_DATE_ERROR",

  // CONFIRM_INSTALLATION
  CONFIRM_INSTALLATION_LOADING = "CONFIRM_INSTALLATION_LOADING",
  CONFIRM_INSTALLATION_SUCCESS = "CONFIRM_INSTALLATION_SUCCESS",
  CONFIRM_INSTALLATION_ERROR = "CONFIRM_INSTALLATION_ERROR",

  // GET NEXT REPAYMENT
  GET_NEXT_REPAYMENT_LOADING = "GET_NEXT_REPAYMENT_LOADING",
  GET_NEXT_REPAYMENT_SUCCESS = "GET_NEXT_REPAYMENT_SUCCESS",
  GET_NEXT_REPAYMENT_ERROR = "GET_NEXT_REPAYMENT_ERROR",

  // UPDATE QUOTE REQUEST LOCATION DATA
  UPDATE_LOCATION_DATA_LOADING = "UPDATE_LOCATION_DATA_LOADING",
  UPDATE_LOCATION_DATA_SUCCESS = "UPDATE_LOCATION_DATA_SUCCESS",
  UPDATE_LOCATION_DATA_ERROR = "UPDATE_LOCATION_DATA_ERROR",

  // GET WARRANTY DETAILS
  GET_WARRANTY_LOADING = "GET_WARRANTY_LOADING",
  GET_WARRANTY_SUCCESS = "GET_WARRANTY_SUCCESS",
  GET_WARRANTY_ERROR = "GET_WARRANTY_ERROR",

  // GET ALL EMPLOYEE LISTS
  GET_EMPLOYEES_LOADING = "GET_EMPLOYEES_LOADING",
  GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS",
  GET_EMPLOYEES_ERROR = "GET_EMPLOYEES_ERROR",

  // GET ALL EMPLOYEE LISTS
  GET_INSTALLATION_COMPONENTS_BY_CATEGORY_LOADING = "GET_INSTALLATION_COMPONENTS_BY_CATEGORY_LOADING",
  GET_INSTALLATION_COMPONENTS_BY_CATEGORY_SUCCESS = "GET_INSTALLATION_COMPONENTS_BY_CATEGORY_SUCCESS",
  GET_INSTALLATION_COMPONENTS_BY_CATEGORY_ERROR = "GET_INSTALLATION_COMPONENTS_BY_CATEGORY_ERROR",

  // GET ALL FINANCIAL INSTITUTIONS
  GET_FINANCIAL_INSTITUTIONS_LOADING = "GET_FINANCIAL_INSTITUTIONS_LOADING",
  GET_FINANCIAL_INSTITUTIONS_SUCCESS = "GET_FINANCIAL_INSTITUTIONS_SUCCESS",
  GET_FINANCIAL_INSTITUTIONS_ERROR = "GET_FINANCIAL_INSTITUTIONS_ERROR",

  // CREATE FINANCIAL QUOTE
  CREATE_FINANCIAL_QUOTE_LOADING = "CREATE_FINANCIAL_QUOTE_LOADING",
  CREATE_FINANCIAL_QUOTE_SUCCESS = "CREATE_FINANCIAL_QUOTE_SUCCESS",
  CREATE_FINANCIAL_QUOTE_ERROR = "CREATE_FINANCIAL_QUOTE_ERROR",

  // GET_QUOTE_REPAYMENTS_RANGE
  GET_QUOTE_REPAYMENTS_RANGE_LOADING = "GET_QUOTE_REPAYMENTS_RANGE_LOADING",
  GET_QUOTE_REPAYMENTS_RANGE_SUCCESS = "GET_QUOTE_REPAYMENTS_RANGE_SUCCESS",
  GET_QUOTE_REPAYMENTS_RANGE_ERROR = "GET_QUOTE_REPAYMENTS_RANGE_ERROR",

  // GET_QUOTE_REPAYMENTS_RANGE
  UPDATE_QUOTE_EQUITY_LOADING = "UPDATE_QUOTE_EQUITY_LOADING",
  UPDATE_QUOTE_EQUITY_SUCCESS = "UPDATE_QUOTE_EQUITY_SUCCESS",
  UPDATE_QUOTE_EQUITY_ERROR = "UPDATE_QUOTE_EQUITY_ERROR",

  // GET BANKS
  GET_BANKS_LOADING = "GET_BANKS_LOADING",
  GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS",
  GET_BANKS_ERROR = "GET_BANKS_ERROR",
}

export interface QuoteMutation {
  // create quote
  [QuoteMutationTypes.CREATE_QUOTE_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.CREATE_QUOTE_SUCCESS](state: QuoteStates): void;
  [QuoteMutationTypes.CREATE_QUOTE_ERROR](
    state: QuoteStates,
    payload: any
  ): void;

  // Refresh quote
  [QuoteMutationTypes.REFRESH_QUOTE_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.REFRESH_QUOTE_SUCCESS](state: QuoteStates): void;
  [QuoteMutationTypes.REFRESH_QUOTE_ERROR](
    state: QuoteStates,
    payload: any
  ): void;

  // get states
  [QuoteMutationTypes.GET_STATES_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.GET_STATES_SUCCESS](
    state: QuoteStates,
    payload: Array<NigerianState>
  ): void;
  [QuoteMutationTypes.GET_STATES_ERROR](state: QuoteStates, payload: any): void;

  // get lga
  [QuoteMutationTypes.GET_LGA_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void;
  [QuoteMutationTypes.GET_LGA_SUCCESS](
    state: QuoteStates,
    payload: Array<LGA>
  ): void;
  [QuoteMutationTypes.GET_LGA_ERROR](state: QuoteStates, payload: any): void;

  // SAVE QUOTE PAYMENT
  [QuoteMutationTypes.SAVE_QUOTE_PAYMENT_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void;
  [QuoteMutationTypes.SAVE_QUOTE_PAYMENT_SUCCESS](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.SAVE_QUOTE_PAYMENT_ERROR](
    state: QuoteStates,
    payload: string
  ): void;

  // CONFIRM INSTALLATION DATE
  [QuoteMutationTypes.CONFIRM_FULFILMENT_DATE_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.CONFIRM_FULFILMENT_DATE_SUCCESS](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.CONFIRM_FULFILMENT_DATE_ERROR](
    state: QuoteStates,
    payload: Status
  ): void;

  // CONFIRM INSTALLATION
  [QuoteMutationTypes.CONFIRM_INSTALLATION_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.CONFIRM_INSTALLATION_SUCCESS](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.CONFIRM_INSTALLATION_ERROR](
    state: QuoteStates,
    payload: Status
  ): void;

  // GET NEXT REPAYMENT
  [QuoteMutationTypes.GET_NEXT_REPAYMENT_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.GET_NEXT_REPAYMENT_SUCCESS](
    state: QuoteStates,
    payload: NextRepayment
  ): void;
  [QuoteMutationTypes.GET_NEXT_REPAYMENT_ERROR](
    state: QuoteStates,
    payload: Status
  ): void;

  // UPDATE quote
  [QuoteMutationTypes.UPDATE_LOCATION_DATA_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.UPDATE_LOCATION_DATA_SUCCESS](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.UPDATE_LOCATION_DATA_ERROR](
    state: QuoteStates,
    payload: any
  ): void;

  // GET WARRANTY DETAILS
  [QuoteMutationTypes.GET_WARRANTY_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.GET_WARRANTY_SUCCESS](
    state: QuoteStates,
    payload: WarrantyDetails
  ): void;
  [QuoteMutationTypes.GET_WARRANTY_ERROR](
    state: QuoteStates,
    payload: Status
  ): void;

  // GET EMPLOYER LISTS
  [QuoteMutationTypes.GET_EMPLOYEES_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.GET_EMPLOYEES_SUCCESS](
    state: QuoteStates,
    payload: Array<EmployeeLists>
  ): void;
  [QuoteMutationTypes.GET_EMPLOYEES_ERROR](
    state: QuoteStates,
    payload: Status
  ): void;

  // GET FINANCIAL INSTITUTIONS
  [QuoteMutationTypes.GET_FINANCIAL_INSTITUTIONS_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.GET_FINANCIAL_INSTITUTIONS_SUCCESS](
    state: QuoteStates,
    payload: Array<FinancialInstitutions>
  ): void;
  [QuoteMutationTypes.GET_FINANCIAL_INSTITUTIONS_ERROR](
    state: QuoteStates,
    payload: Status
  ): void;

  // CREATE FINANCIAL QUOTE
  [QuoteMutationTypes.CREATE_FINANCIAL_QUOTE_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.CREATE_FINANCIAL_QUOTE_SUCCESS](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.CREATE_FINANCIAL_QUOTE_ERROR](
    state: QuoteStates,
    payload: Status
  ): void;

  // get states
  [QuoteMutationTypes.GET_INSTALLATION_COMPONENTS_BY_CATEGORY_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.GET_INSTALLATION_COMPONENTS_BY_CATEGORY_SUCCESS](
    state: QuoteStates,
    payload: Array<ProductComponentResponse>
  ): void;
  [QuoteMutationTypes.GET_INSTALLATION_COMPONENTS_BY_CATEGORY_ERROR](
    state: QuoteStates,
    payload: any
  ): void;

  // GET REPAYMENT RANGES
  [QuoteMutationTypes.GET_QUOTE_REPAYMENTS_RANGE_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.GET_QUOTE_REPAYMENTS_RANGE_SUCCESS](
    state: QuoteStates,
    payload: Array<RepaymentRanges>
  ): void;
  [QuoteMutationTypes.GET_QUOTE_REPAYMENTS_RANGE_ERROR](
    state: QuoteStates,
    payload: any
  ): void;

  // CREATE FINANCIAL QUOTE
  [QuoteMutationTypes.UPDATE_QUOTE_EQUITY_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.UPDATE_QUOTE_EQUITY_SUCCESS](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.UPDATE_QUOTE_EQUITY_ERROR](
    state: QuoteStates,
    payload: Status
  ): void;

  // GET BANKS
  [QuoteMutationTypes.GET_BANKS_LOADING](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.GET_BANKS_SUCCESS](
    state: QuoteStates,
    payload: Status
  ): void;
  [QuoteMutationTypes.GET_BANKS_ERROR](
    state: QuoteStates,
    payload: Status
  ): void;
}

export const quoteMutations: MutationTree<QuoteStates> & QuoteMutation = {
  // Create quote
  [QuoteMutationTypes.CREATE_QUOTE_LOADING](state: QuoteStates): void {
    state.status = Status.LOADING;
  },
  [QuoteMutationTypes.CREATE_QUOTE_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.CREATE_QUOTE_SUCCESS](state: QuoteStates): void {
    state.status = Status.SUCCESS;
  },

  // Refresh quote
  [QuoteMutationTypes.REFRESH_QUOTE_LOADING](
    state: QuoteStates,
    payload: Status
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.REFRESH_QUOTE_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.REFRESH_QUOTE_SUCCESS](state: QuoteStates): void {
    state.status = Status.SUCCESS;
  },

  // GET STATES
  [QuoteMutationTypes.GET_STATES_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.GET_STATES_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.GET_STATES_SUCCESS](
    state: QuoteStates,
    payload: Array<NigerianState>
  ): void {
    state.status = Status.SUCCESS;
    state.nigerianStates = payload;
  },

  // GET LGA
  [QuoteMutationTypes.GET_LGA_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.GET_LGA_ERROR](state: QuoteStates, error: string): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.GET_LGA_SUCCESS](
    state: QuoteStates,
    payload: Array<LGA>
  ): void {
    state.status = Status.SUCCESS;
    state.lga = payload;
  },

  // SAVE QUOTE PAYMENT
  [QuoteMutationTypes.SAVE_QUOTE_PAYMENT_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.SAVE_QUOTE_PAYMENT_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.SAVE_QUOTE_PAYMENT_SUCCESS](
    state: QuoteStates,
    payload: Status.SUCCESS
  ): void {
    state.status = payload;
  },

  // CONFIRM INSTALLATION DATE
  [QuoteMutationTypes.CONFIRM_FULFILMENT_DATE_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.CONFIRM_FULFILMENT_DATE_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.CONFIRM_FULFILMENT_DATE_SUCCESS](
    state: QuoteStates,
    payload: Status.SUCCESS
  ): void {
    state.status = payload;
  },

  // CONFIRM INSTALLATION DATE
  [QuoteMutationTypes.CONFIRM_INSTALLATION_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.CONFIRM_INSTALLATION_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.CONFIRM_INSTALLATION_SUCCESS](
    state: QuoteStates,
    payload: Status.SUCCESS
  ): void {
    state.status = payload;
  },

  // GET NEXT REPAYMENT
  [QuoteMutationTypes.GET_NEXT_REPAYMENT_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.GET_NEXT_REPAYMENT_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.GET_NEXT_REPAYMENT_SUCCESS](
    state: QuoteStates,
    payload: NextRepayment
  ): void {
    state.status = Status.SUCCESS;
    state.nextRepayment = payload;
  },

  // Update Quote Request
  [QuoteMutationTypes.UPDATE_LOCATION_DATA_LOADING](
    state: QuoteStates,
    payload: Status
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.UPDATE_LOCATION_DATA_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.UPDATE_LOCATION_DATA_SUCCESS](
    state: QuoteStates,
    payload: Status
  ): void {
    state.status = payload;
  },

  // GET WARRANTY DETAILS
  [QuoteMutationTypes.GET_WARRANTY_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.GET_WARRANTY_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.GET_WARRANTY_SUCCESS](
    state: QuoteStates,
    payload: WarrantyDetails
  ): void {
    state.status = Status.SUCCESS;
    state.warranty = payload;
  },

  // GET EMPLOYEE LISTS
  [QuoteMutationTypes.GET_EMPLOYEES_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.GET_EMPLOYEES_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.GET_EMPLOYEES_SUCCESS](
    state: QuoteStates,
    payload: Array<EmployeeLists>
  ): void {
    state.status = Status.SUCCESS;
    state.employees = payload;
  },

  // GET EMPLOYEE LISTS
  [QuoteMutationTypes.GET_FINANCIAL_INSTITUTIONS_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.GET_FINANCIAL_INSTITUTIONS_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.GET_FINANCIAL_INSTITUTIONS_SUCCESS](
    state: QuoteStates,
    payload: Array<FinancialInstitutions>
  ): void {
    state.status = Status.SUCCESS;
    state.financialInstitutions = payload;
  },

  // GET STATES
  [QuoteMutationTypes.GET_INSTALLATION_COMPONENTS_BY_CATEGORY_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.GET_INSTALLATION_COMPONENTS_BY_CATEGORY_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.GET_INSTALLATION_COMPONENTS_BY_CATEGORY_SUCCESS](
    state: QuoteStates,
    payload: Array<ProductComponentResponse>
  ): void {
    state.status = Status.SUCCESS;
    state.productComponentCat = payload;
  },

  // CREATE FINANCIAL QUOTE
  [QuoteMutationTypes.CREATE_FINANCIAL_QUOTE_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.CREATE_FINANCIAL_QUOTE_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.CREATE_FINANCIAL_QUOTE_SUCCESS](
    state: QuoteStates,
    payload: Status.SUCCESS
  ): void {
    state.status = payload;
  },

  // GET QUOTE REPAYMENT RANGES
  [QuoteMutationTypes.GET_QUOTE_REPAYMENTS_RANGE_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.GET_QUOTE_REPAYMENTS_RANGE_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.GET_QUOTE_REPAYMENTS_RANGE_SUCCESS](
    state: QuoteStates,
    payload: Array<RepaymentRanges>
  ): void {
    state.status = Status.SUCCESS;
    state.repaymentRanges = payload;
  },

  // update quote equity percentage
  [QuoteMutationTypes.UPDATE_QUOTE_EQUITY_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.UPDATE_QUOTE_EQUITY_ERROR](
    state: QuoteStates,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.quoteError = error;
  },
  [QuoteMutationTypes.UPDATE_QUOTE_EQUITY_SUCCESS](
    state: QuoteStates,
    payload: Status.SUCCESS
  ): void {
    state.status = payload;
  },

  // GET BANKS
  [QuoteMutationTypes.GET_BANKS_LOADING](
    state: QuoteStates,
    payload: Status.LOADING
  ): void {
    state.status = payload;
  },
  [QuoteMutationTypes.GET_BANKS_ERROR](
    state: QuoteStates,
    error: Status.ERROR
  ): void {
    state.status = error;
  },
  [QuoteMutationTypes.GET_BANKS_SUCCESS](
    state: QuoteStates,
    payload: Status.SUCCESS
  ): void {
    state.status = payload;
  },
};
