import { MutationTree } from "vuex";
import { SupportModuleState } from "./state";
import { Status, UserInfo } from "@/types";
import {
  ProductEntity,
  Products,
  SupportQuoteResponse,
} from "@/views/Support/types";
import { AdminIssueDetails, IssueDetails } from "@/views/Issues/types";
import {
  CordinatorDashboardReport,
  CordinatorReferredCustomerDetails,
  CordinatorReferredCustomerList,
  SalesBreakdown,
  SalesReferal,
  SalesTeamDashboardReport,
  ScheduleResponse,
  SchedulesResponse,
} from "@/views/Admin/types";
import { QuoteResponseRequest } from "@/views/Dashboard/types";

export enum SupportMutationTypes {
  // GET transactions
  GET_ACTIVE_QUOTES_LOADING = "GET_ACTIVE_QUOTES_LOADING",
  GET_ACTIVE_QUOTES_SUCCESS = "GET_ACTIVE_QUOTES_SUCCESS",
  GET_ACTIVE_QUOTES_ERROR = "GET_ACTIVE_QUOTES_ERROR",

  GET_NOT_RESPONDED_QUOTES_LOADING = "GET_NOT_RESPONDED_QUOTES_LOADING",
  GET_NOT_RESPONDED_QUOTES_SUCCESS = "GET_NOT_RESPONDED_QUOTES_SUCCESS",
  GET_NOT_RESPONDED_QUOTES_ERROR = "GET_NOT_RESPONDED_QUOTES_ERROR",

  GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_LOADING = "GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_LOADING",
  GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_SUCCESS = "GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_SUCCESS",
  GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_ERROR = "GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_ERROR",

  GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_LOADING = "GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_LOADING",
  GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_SUCCESS = "GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_SUCCESS",
  GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_ERROR = "GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_ERROR",

  GET_AWAITING_INSTALLATION_QUOTES_LOADING = "GET_AWAITING_INSTALLATION_QUOTES_LOADING",
  GET_AWAITING_INSTALLATION_QUOTES_SUCCESS = "GET_AWAITING_INSTALLATION_QUOTES_SUCCESS",
  GET_AWAITING_INSTALLATION_QUOTES_ERROR = "GET_AWAITING_INSTALLATION_QUOTES_ERROR",

  GET_COMPLETED_INSTALLATION_QUOTES_LOADING = "GET_COMPLETED_INSTALLATION_QUOTES_LOADING",
  GET_COMPLETED_INSTALLATION_QUOTES_SUCCESS = "GET_COMPLETED_INSTALLATION_QUOTES_SUCCESS",
  GET_COMPLETED_INSTALLATION_QUOTES_ERROR = "GET_COMPLETED_INSTALLATION_QUOTES_ERROR",

  //GET PRODUCTS
  GET_PRODUCTS_LOADING = "GET_PRODUCTS_LOADING",
  GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR",

  //GET PRODUCT DETAIL
  GET_PRODUCT_DETAIL_LOADING = "GET_PRODUCT_DETAIL_LOADING",
  GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS",
  GET_PRODUCT_DETAIL_ERROR = "GET_PRODUCT_DETAIL_ERROR",

  //GET ISSUES
  GET_ISSUES_LOADING = "GET_ISSUES_LOADING",
  GET_ISSUES_SUCCESS = "GET_ISSUES_SUCCESS",
  GET_ISSUES_ERROR = "GET_ISSUES_ERROR",

  //GET ISSUES DETAILS
  GET_ISSUE_DETAILS_LOADING = "GET_ISSUE_DETAILS_LOADING",
  GET_ISSUE_DETAILS_SUCCESS = "GET_ISSUE_DETAILS_SUCCESS",
  GET_ISSUE_DETAILS_ERROR = "GET_ISSUE_DETAILS_ERROR",

  //GET ACTIVE INSTALLATIONS
  GET_ACTIVE_INSTALLATIONS_LOADING = "GET_ACTIVE_INSTALLATIONS_LOADING",
  GET_ACTIVE_INSTALLATIONS_SUCCESS = "GET_ACTIVE_INSTALLATIONS_SUCCESS",
  GET_ACTIVE_INSTALLATIONS_ERROR = "GET_ACTIVE_INSTALLATIONS_ERROR",

  //GET COMPLETED INSTALLATIONS
  GET_COMPLETED_INSTALLATIONS_LOADING = "GET_COMPLETED_DETAILS_LOADING",
  GET_COMPLETED_INSTALLATIONS_SUCCESS = "GET_COMPLETED_INSTALLATIONS_SUCCESS",
  GET_COMPLETED_INSTALLATIONS_ERROR = "GET_COMPLETED_INSTALLATIONS_ERROR",

  //SET FULFILMENT DATE
  SET_FULFILMENT_DATE_LOADING = "SET_FULFILMENT_DATE_LOADING",
  SET_FULFILMENT_DATE_SUCCESS = "SET_FULFILMENT_DATE_SUCCESS",
  SET_FULFILMENT_DATE_ERROR = "SET_FULFILMENT_DATE_ERROR",

  //GET SUPPORT USERS
  GET_SUPPORT_USERS_LOADING = "GET_SUPPORT_USERS_LOADING",
  GET_SUPPORT_USERS_SUCCESS = "GET_SUPPORT_USERS_SUCCESS",
  GET_SUPPORT_USERS_ERROR = "GET_SUPPORT_USERS_ERROR",

  //ASSIGN SUPPORT TO QUOTE
  ASSIGN_QUOTE_SUPPORT_LOADING = "ASSIGN_QUOTE_SUPPORT_LOADING",
  ASSIGN_QUOTE_SUPPORT_SUCCESS = "ASSIGN_QUOTE_SUPPORT_SUCCESS",
  ASSIGN_QUOTE_SUPPORT_ERROR = "ASSIGN_QUOTE_SUPPORT_ERROR",

  //ASSIGN PRODCUT TO QUOTE
  ASSIGN_QUOTE_PRODUCT_LOADING = "ASSIGN_QUOTE_PRODUCT_LOADING",
  ASSIGN_QUOTE_PRODUCT_SUCCESS = "ASSIGN_QUOTE_PRODUCT_SUCCESS",
  ASSIGN_QUOTE_PRODUCT_ERROR = "ASSIGN_QUOTE_PRODUCT_ERROR",

  //CONFIRM INSTALLATION COMPLETE
  CONFIRM_INSTALLATION_COMPLETE_LOADING = "CONFIRM_INSTALLATION_COMPLETE_LOADING",
  CONFIRM_INSTALLATION_COMPLETE_SUCCESS = "CONFIRM_INSTALLATION_COMPLETE_SUCCESS",
  CONFIRM_INSTALLATION_COMPLETE_ERROR = "CONFIRM_INSTALLATION_COMPLETE_ERROR",

  //UPDATE INSTALLATION COST
  UPDATE_INSTALLATION_COST_LOADING = "UPDATE_INSTALLATION_COST_LOADING",
  UPDATE_INSTALLATION_COST_SUCCESS = "UPDATE_INSTALLATION_COST_SUCCESS",
  UPDATE_INSTALLATION_COST_ERROR = "UPDATE_INSTALLATION_COST_ERROR",

  //UPDATE TRANSPORTATION COST
  UPDATE_TRANSPORTATION_COST_LOADING = "UPDATE_TRANSPORTATION_COST_LOADING",
  UPDATE_TRANSPORTATION_COST_SUCCESS = "UPDATE_TRANSPORTATION_COST_SUCCESS",
  UPDATE_TRANSPORTATION_COST_ERROR = "UPDATE_TRANSPORTATION_COST_ERROR",

  //UPLOAD UNDERTAKEN
  UPLOAD_UNDERTAKEN_LOADING = "UPLOAD_UNDERTAKEN_LOADING",
  UPLOAD_UNDERTAKEN_SUCCESS = "UPLOAD_UNDERTAKEN_SUCCESS",
  UPLOAD_UNDERTAKEN_ERROR = "UPLOAD_UNDERTAKEN_ERROR",

  //CLOSE MONITORING SCHEDULE
  CLOSE_MONITORING_SCHEDULE_LOADING = "CLOSE_MONITORING_SCHEDULE_LOADING",
  CLOSE_MONITORING_SCHEDULE_SUCCESS = "CLOSE_MONITORING_SCHEDULE_SUCCESS",
  CLOSE_MONITORING_SCHEDULE_ERROR = "CLOSE_MONITORING_SCHEDULE_ERROR",

  //GET MONITORING SCHEDULES
  GET_MONITORING_SCHEDULES_LOADING = "GET_MONITORING_SCHEDULES_LOADING",
  GET_MONITORING_SCHEDULES_SUCCESS = "GET_MONITORING_SCHEDULES_SUCCESS",
  GET_MONITORING_SCHEDULES_ERROR = "GET_MONITORING_SCHEDULES_ERROR",

  // GET MONITORING SCHEDULE BY ID
  GET_MONITORING_SCHEDULE_BY_ID_LOADING = "GET_MONITORING_SCHEDULE_BY_ID_LOADING",
  GET_MONITORING_SCHEDULE_BY_ID_SUCCESS = "GET_MONITORING_SCHEDULE_BY_ID_LOADING_SUCCESS",
  GET_MONITORING_SCHEDULE_BY_ID_ERROR = "GET_MONITORING_SCHEDULE_BY_ID_LOADING_ERROR",

  /*----------------
    SALES TEAM DATA
  -----------------*/
  //SALES TEAM DASHBOARD REPORT
  GET_SALES_TEAM_DASHBOARD_REPORT_LOADING = "GET_SALES_TEAM_DASHBOARD_REPORT_LOADING",
  GET_SALES_TEAM_DASHBOARD_REPORT_SUCCESS = "GET_SALES_TEAM_DASHBOARD_REPORT_SUCCESS",
  GET_SALES_TEAM_DASHBOARD_REPORT_ERROR = "GET_SALES_TEAM_DASHBOARD_REPORT_ERROR",

  //SALES TEAM DASHBOARD REPORT
  GET_CORDINATOR_DASHBOARD_REPORT_LOADING = "GET_CORDINATOR_DASHBOARD_REPORT_LOADING",
  GET_CORDINATOR_DASHBOARD_REPORT_SUCCESS = "GET_CORDINATOR_DASHBOARD_REPORT_SUCCESS",
  GET_CORDINATOR_DASHBOARD_REPORT_ERROR = "GET_CORDINATOR_DASHBOARD_REPORT_ERROR",

  //SALES RECENT REFERAL
  GET_SALES_TEAM_RECENT_REFERAL_LOADING = "GET_SALES_TEAM_RECENT_REFERAL_LOADING",
  GET_SALES_TEAM_RECENT_REFERAL_SUCCESS = "GET_SALES_TEAM_RECENT_REFERAL_SUCCESS",
  GET_SALES_TEAM_RECENT_REFERAL_ERROR = "GET_SALES_TEAM_RECENT_REFERAL_ERROR",

  //CORDINATOR GET_CORDINATOR_RECENT_REFERAL
  GET_CORDINATOR_RECENT_REFERAL_LOADING = "GET_CORDINATOR_RECENT_REFERAL_LOADING",
  GET_CORDINATOR_RECENT_REFERAL_SUCCESS = "GET_CORDINATOR_RECENT_REFERAL_SUCCESS",
  GET_CORDINATOR_RECENT_REFERAL_ERROR = "GET_CORDINATOR_RECENT_REFERAL_ERROR",

  //SALES ALL REFERAL
  GET_SALES_TEAM_ALL_REFERAL_LOADING = "GET_SALES_TEAM_ALL_REFERAL_LOADING",
  GET_SALES_TEAM_ALL_REFERAL_SUCCESS = "GET_SALES_TEAM_ALL_REFERAL_SUCCESS",
  GET_SALES_TEAM_ALL_REFERAL_ERROR = "GET_SALES_TEAM_ALL_REFERAL_ERROR",

  //SALES DETAILS BY CUSTOMER ID
  GET_SALES_DETAILS_BY_CUSTOMER_ID_LOADING = "GET_SALES_DETAILS_BY_CUSTOMER_ID_LOADING",
  GET_SALES_DETAILS_BY_CUSTOMER_ID_SUCCESS = "GET_SALES_DETAILS_BY_CUSTOMER_ID_SUCCESS",
  GET_SALES_DETAILS_BY_CUSTOMER_ID_ERROR = "GET_SALES_DETAILS_BY_CUSTOMER_ID_ERROR",

  //SALES ALL QUOTES
  GET_SALES_ALL_QUOTES_LOADING = "GET_SALES_ALL_QUOTES_LOADING",
  GET_SALES_ALL_QUOTES_SUCCESS = "GET_SALES_ALL_QUOTES_SUCCESS",
  GET_SALES_ALL_QUOTES_ERROR = "GET_SALES_ALL_QUOTES_ERROR",

  //CORDINATOR OTHER REFERRALS
  GET_CORDINATOR_OTHER_REFERAL_LOADING = "GET_CORDINATOR_OTHER_REFERAL_LOADING",
  GET_CORDINATOR_OTHER_REFERAL_SUCCESS = "GET_CORDINATOR_OTHER_REFERAL_SUCCESS",
  GET_CORDINATOR_OTHER_REFERAL_ERROR = "GET_CORDINATOR_OTHER_REFERAL_ERROR",

  //CORDINATOR GET REFERRAL BY ID
  GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_LOADING = "GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_LOADING",
  GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_SUCCESS = "GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_SUCCESS",
  GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_ERROR = "GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_ERROR",
}

export interface SupportMutation {
  // get quotes
  [SupportMutationTypes.GET_ACTIVE_QUOTES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_ACTIVE_QUOTES_SUCCESS](
    state: SupportModuleState,
    payload: Array<SupportQuoteResponse>
  ): void;
  [SupportMutationTypes.GET_ACTIVE_QUOTES_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // get UNRESPONDED QUOTES
  [SupportMutationTypes.GET_NOT_RESPONDED_QUOTES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_NOT_RESPONDED_QUOTES_SUCCESS](
    state: SupportModuleState,
    payload: Array<SupportQuoteResponse>
  ): void;
  [SupportMutationTypes.GET_NOT_RESPONDED_QUOTES_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // get AWAITING CUSTOMER RESPONSE QUOTES
  [SupportMutationTypes.GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_SUCCESS](
    state: SupportModuleState,
    payload: Array<SupportQuoteResponse>
  ): void;
  [SupportMutationTypes.GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // get AWAITING ENGINEER ASSIGNMENT QUOTES
  [SupportMutationTypes.GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_SUCCESS](
    state: SupportModuleState,
    payload: Array<SupportQuoteResponse>
  ): void;
  [SupportMutationTypes.GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // get AWAITING INSTALLATION QUOTES
  [SupportMutationTypes.GET_AWAITING_INSTALLATION_QUOTES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_AWAITING_INSTALLATION_QUOTES_SUCCESS](
    state: SupportModuleState,
    payload: Array<SupportQuoteResponse>
  ): void;
  [SupportMutationTypes.GET_AWAITING_INSTALLATION_QUOTES_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // get COMPLETED INSTALLATION QUOTES
  [SupportMutationTypes.GET_COMPLETED_INSTALLATION_QUOTES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_COMPLETED_INSTALLATION_QUOTES_SUCCESS](
    state: SupportModuleState,
    payload: Array<SupportQuoteResponse>
  ): void;
  [SupportMutationTypes.GET_COMPLETED_INSTALLATION_QUOTES_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  //GET PRODUCTS
  [SupportMutationTypes.GET_PRODUCTS_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_PRODUCTS_SUCCESS](
    state: SupportModuleState,
    payload: Array<Products>
  ): void;
  [SupportMutationTypes.GET_PRODUCTS_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  //GET PRODUCT DETAIL
  [SupportMutationTypes.GET_PRODUCT_DETAIL_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_PRODUCT_DETAIL_SUCCESS](
    state: SupportModuleState,
    payload: ProductEntity
  ): void;
  [SupportMutationTypes.GET_PRODUCT_DETAIL_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // get issues
  [SupportMutationTypes.GET_ISSUES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_ISSUES_SUCCESS](
    state: SupportModuleState,
    payload: Array<AdminIssueDetails>
  ): void;
  [SupportMutationTypes.GET_ISSUES_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // get issue details
  [SupportMutationTypes.GET_ISSUE_DETAILS_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_ISSUE_DETAILS_SUCCESS](
    state: SupportModuleState,
    payload: IssueDetails
  ): void;
  [SupportMutationTypes.GET_ISSUE_DETAILS_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // get ACTIVE INSTALLATIONS
  [SupportMutationTypes.GET_ACTIVE_INSTALLATIONS_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_ACTIVE_INSTALLATIONS_SUCCESS](
    state: SupportModuleState,
    payload: any
  ): void;
  [SupportMutationTypes.GET_ACTIVE_INSTALLATIONS_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // get COMPLETED INSTALLATIONS
  [SupportMutationTypes.GET_COMPLETED_INSTALLATIONS_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_COMPLETED_INSTALLATIONS_SUCCESS](
    state: SupportModuleState,
    payload: any
  ): void;
  [SupportMutationTypes.GET_COMPLETED_INSTALLATIONS_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // Set fulfilment date
  [SupportMutationTypes.SET_FULFILMENT_DATE_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.SET_FULFILMENT_DATE_SUCCESS](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.SET_FULFILMENT_DATE_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // GET SUPPORT USERS
  [SupportMutationTypes.GET_SUPPORT_USERS_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_SUPPORT_USERS_SUCCESS](
    state: SupportModuleState,
    payload: Array<UserInfo>
  ): void;
  [SupportMutationTypes.GET_SUPPORT_USERS_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // ASsign engineer to quote
  [SupportMutationTypes.ASSIGN_QUOTE_SUPPORT_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.ASSIGN_QUOTE_SUPPORT_SUCCESS](
    state: SupportModuleState,
    payload: any
  ): void;
  [SupportMutationTypes.ASSIGN_QUOTE_SUPPORT_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // ASsign engineer to quote
  [SupportMutationTypes.ASSIGN_QUOTE_PRODUCT_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.ASSIGN_QUOTE_PRODUCT_SUCCESS](
    state: SupportModuleState,
    payload: any
  ): void;
  [SupportMutationTypes.ASSIGN_QUOTE_PRODUCT_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // CONFIRM INSTALLATION COMPLETE
  [SupportMutationTypes.CONFIRM_INSTALLATION_COMPLETE_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.CONFIRM_INSTALLATION_COMPLETE_SUCCESS](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.CONFIRM_INSTALLATION_COMPLETE_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  //UPDATE INSTALLATION COST
  [SupportMutationTypes.UPDATE_INSTALLATION_COST_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.UPDATE_INSTALLATION_COST_SUCCESS](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.UPDATE_INSTALLATION_COST_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  //UPDATE TRANSPORTATION COST
  [SupportMutationTypes.UPDATE_TRANSPORTATION_COST_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.UPDATE_TRANSPORTATION_COST_SUCCESS](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.UPDATE_TRANSPORTATION_COST_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  //UPLOAD UNDERTAKEN
  [SupportMutationTypes.UPLOAD_UNDERTAKEN_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.UPLOAD_UNDERTAKEN_SUCCESS](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.UPLOAD_UNDERTAKEN_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // CLOSE MONITORING SCHEDULE
  [SupportMutationTypes.CLOSE_MONITORING_SCHEDULE_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.CLOSE_MONITORING_SCHEDULE_SUCCESS](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.CLOSE_MONITORING_SCHEDULE_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // GET MONITORING SCHEDULES
  [SupportMutationTypes.GET_MONITORING_SCHEDULES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_MONITORING_SCHEDULES_SUCCESS](
    state: SupportModuleState,
    payload: Array<SchedulesResponse>
  ): void;
  [SupportMutationTypes.GET_MONITORING_SCHEDULES_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // GET MONITORING SCHEDULES
  [SupportMutationTypes.GET_MONITORING_SCHEDULE_BY_ID_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_MONITORING_SCHEDULE_BY_ID_SUCCESS](
    state: SupportModuleState,
    payload: ScheduleResponse
  ): void;
  [SupportMutationTypes.GET_MONITORING_SCHEDULE_BY_ID_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  /*=-------------
    SALES TEAM DATA
  ---------------*/
  //GET SALES DASHBOARD REPORT
  [SupportMutationTypes.GET_SALES_TEAM_DASHBOARD_REPORT_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_SALES_TEAM_DASHBOARD_REPORT_SUCCESS](
    state: SupportModuleState,
    payload: SalesTeamDashboardReport
  ): void;
  [SupportMutationTypes.GET_SALES_TEAM_DASHBOARD_REPORT_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  //GET CORINATOR DASHBOARD REPORT
  [SupportMutationTypes.GET_CORDINATOR_DASHBOARD_REPORT_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_CORDINATOR_DASHBOARD_REPORT_SUCCESS](
    state: SupportModuleState,
    payload: CordinatorDashboardReport
  ): void;
  [SupportMutationTypes.GET_CORDINATOR_DASHBOARD_REPORT_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  //GET RECENT REFERALS
  [SupportMutationTypes.GET_SALES_TEAM_RECENT_REFERAL_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_SALES_TEAM_RECENT_REFERAL_SUCCESS](
    state: SupportModuleState,
    payload: Array<SalesReferal>
  ): void;
  [SupportMutationTypes.GET_SALES_TEAM_RECENT_REFERAL_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  //GET ALL REFERALS
  [SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_SUCCESS](
    state: SupportModuleState,
    payload: Array<SalesReferal>
  ): void;
  [SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  //GET SALES DETAILS BY CUSTOMER ID
  [SupportMutationTypes.GET_SALES_DETAILS_BY_CUSTOMER_ID_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_SALES_DETAILS_BY_CUSTOMER_ID_SUCCESS](
    state: SupportModuleState,
    payload: SalesBreakdown
  ): void;
  [SupportMutationTypes.GET_SALES_DETAILS_BY_CUSTOMER_ID_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  // GET ALL SALES AGENT QUOTES
  [SupportMutationTypes.GET_SALES_ALL_QUOTES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_SALES_ALL_QUOTES_SUCCESS](
    state: SupportModuleState,
    payload: Array<QuoteResponseRequest>
  ): void;
  [SupportMutationTypes.GET_SALES_ALL_QUOTES_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  //CORDINATOR RECENT REFERRAL
  [SupportMutationTypes.GET_CORDINATOR_RECENT_REFERAL_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_CORDINATOR_RECENT_REFERAL_SUCCESS](
    state: SupportModuleState,
    payload: Array<CordinatorReferredCustomerDetails>
  ): void;
  [SupportMutationTypes.GET_CORDINATOR_RECENT_REFERAL_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  //CORDINATOR OTHER REFERRAL
  [SupportMutationTypes.GET_CORDINATOR_OTHER_REFERAL_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_CORDINATOR_OTHER_REFERAL_SUCCESS](
    state: SupportModuleState,
    payload: Array<CordinatorReferredCustomerDetails>
  ): void;
  [SupportMutationTypes.GET_CORDINATOR_OTHER_REFERAL_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;

  //CORDINATOR REFERRAL DETAILS BY CUSTOMER ID
  [SupportMutationTypes.GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void;
  [SupportMutationTypes.GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_SUCCESS](
    state: SupportModuleState,
    payload: CordinatorReferredCustomerDetails
  ): void;
  [SupportMutationTypes.GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_ERROR](
    state: SupportModuleState,
    payload: Status
  ): void;
}

export const supportMutations: MutationTree<SupportModuleState> &
  SupportMutation = {
  // Get quotes
  [SupportMutationTypes.GET_ACTIVE_QUOTES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_ACTIVE_QUOTES_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_ACTIVE_QUOTES_SUCCESS](
    state: SupportModuleState,
    payload: Array<SupportQuoteResponse>
  ): void {
    state.status = Status.SUCCESS;
    state.activeQuotes = payload;
  },

  // Get UNRESPONDED QUOTES
  [SupportMutationTypes.GET_NOT_RESPONDED_QUOTES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_NOT_RESPONDED_QUOTES_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_NOT_RESPONDED_QUOTES_SUCCESS](
    state: SupportModuleState,
    payload: Array<SupportQuoteResponse>
  ): void {
    state.status = Status.SUCCESS;
    state.activeQuotes = payload;
  },

  // Get AWAITING CUSTOMER RESPONSE QUOTES
  [SupportMutationTypes.GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_SUCCESS](
    state: SupportModuleState,
    payload: Array<SupportQuoteResponse>
  ): void {
    state.status = Status.SUCCESS;
    state.activeQuotes = payload;
  },

  // Get AWAITING ENGINEER ASSIGNMENT QUOTES
  [SupportMutationTypes.GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_SUCCESS](
    state: SupportModuleState,
    payload: Array<SupportQuoteResponse>
  ): void {
    state.status = Status.SUCCESS;
    state.activeQuotes = payload;
  },

  // Get AWAITING INSTALLATION QUOTES
  [SupportMutationTypes.GET_AWAITING_INSTALLATION_QUOTES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_AWAITING_INSTALLATION_QUOTES_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_AWAITING_INSTALLATION_QUOTES_SUCCESS](
    state: SupportModuleState,
    payload: Array<SupportQuoteResponse>
  ): void {
    state.status = Status.SUCCESS;
    state.activeQuotes = payload;
  },

  // Get COMPLETED INSTALLATION QUOTES
  [SupportMutationTypes.GET_COMPLETED_INSTALLATION_QUOTES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_COMPLETED_INSTALLATION_QUOTES_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_COMPLETED_INSTALLATION_QUOTES_SUCCESS](
    state: SupportModuleState,
    payload: Array<SupportQuoteResponse>
  ): void {
    state.status = Status.SUCCESS;
    state.activeQuotes = payload;
  },

  // Get Product
  [SupportMutationTypes.GET_PRODUCTS_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_PRODUCTS_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_PRODUCTS_SUCCESS](
    state: SupportModuleState,
    payload: Array<Products>
  ): void {
    state.status = Status.SUCCESS;
    state.products = payload;
  },

  // Get Product detail
  [SupportMutationTypes.GET_PRODUCT_DETAIL_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_PRODUCT_DETAIL_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_PRODUCT_DETAIL_SUCCESS](
    state: SupportModuleState,
    payload: ProductEntity
  ): void {
    state.status = Status.SUCCESS;
    state.productEntity = payload;
  },

  // Get Issues
  [SupportMutationTypes.GET_ISSUES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_ISSUES_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_ISSUES_SUCCESS](
    state: SupportModuleState,
    payload: Array<AdminIssueDetails>
  ): void {
    state.status = Status.SUCCESS;
    state.issues = payload;
  },

  // Get Issue detail
  [SupportMutationTypes.GET_ISSUE_DETAILS_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_ISSUE_DETAILS_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_ISSUE_DETAILS_SUCCESS](
    state: SupportModuleState,
    payload: IssueDetails
  ): void {
    state.status = Status.SUCCESS;
    state.issue = payload;
  },

  // Get active installation
  [SupportMutationTypes.GET_ACTIVE_INSTALLATIONS_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_ACTIVE_INSTALLATIONS_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_ACTIVE_INSTALLATIONS_SUCCESS](
    state: SupportModuleState,
    payload: any
  ): void {
    state.status = Status.SUCCESS;
    state.installerQuotes = payload;
  },

  // Get active installation
  [SupportMutationTypes.GET_COMPLETED_INSTALLATIONS_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_COMPLETED_INSTALLATIONS_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_COMPLETED_INSTALLATIONS_SUCCESS](
    state: SupportModuleState,
    payload: any
  ): void {
    state.status = Status.SUCCESS;
    state.installerQuotes = payload;
  },

  // set fulfilment date
  [SupportMutationTypes.SET_FULFILMENT_DATE_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.SET_FULFILMENT_DATE_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.SET_FULFILMENT_DATE_SUCCESS](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },

  // Get support users
  [SupportMutationTypes.GET_SUPPORT_USERS_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_SUPPORT_USERS_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_SUPPORT_USERS_SUCCESS](
    state: SupportModuleState,
    payload: Array<UserInfo>
  ): void {
    state.status = Status.SUCCESS;
    state.supportUsers = payload;
  },

  // Assign Engineers
  [SupportMutationTypes.ASSIGN_QUOTE_SUPPORT_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.ASSIGN_QUOTE_SUPPORT_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.ASSIGN_QUOTE_SUPPORT_SUCCESS](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },

  // UPDATE INSTALLATION COST
  [SupportMutationTypes.UPDATE_INSTALLATION_COST_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.UPDATE_INSTALLATION_COST_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.UPDATE_INSTALLATION_COST_SUCCESS](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },

  // UPDATE TRANSPORTATION COST
  [SupportMutationTypes.UPDATE_TRANSPORTATION_COST_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.UPDATE_TRANSPORTATION_COST_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.UPDATE_TRANSPORTATION_COST_SUCCESS](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },

  // UPLOAD UNDERTAKEN
  [SupportMutationTypes.UPLOAD_UNDERTAKEN_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.UPLOAD_UNDERTAKEN_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.UPLOAD_UNDERTAKEN_SUCCESS](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },

  // Assign Engineers
  [SupportMutationTypes.ASSIGN_QUOTE_PRODUCT_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.ASSIGN_QUOTE_PRODUCT_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.ASSIGN_QUOTE_PRODUCT_SUCCESS](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },

  // Assign Engineers
  [SupportMutationTypes.CONFIRM_INSTALLATION_COMPLETE_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.CONFIRM_INSTALLATION_COMPLETE_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.CONFIRM_INSTALLATION_COMPLETE_SUCCESS](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },

  // CLOSE SCHEDULE
  [SupportMutationTypes.CLOSE_MONITORING_SCHEDULE_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.CLOSE_MONITORING_SCHEDULE_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.CLOSE_MONITORING_SCHEDULE_SUCCESS](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },

  // GET MONITORING SCHEDULES
  [SupportMutationTypes.GET_MONITORING_SCHEDULES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_MONITORING_SCHEDULES_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_MONITORING_SCHEDULES_SUCCESS](
    state: SupportModuleState,
    payload: Array<SchedulesResponse>
  ): void {
    state.monitorSchedules = payload;
  },

  // GET SCHEDULE BY ID
  [SupportMutationTypes.GET_MONITORING_SCHEDULE_BY_ID_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_MONITORING_SCHEDULE_BY_ID_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_MONITORING_SCHEDULE_BY_ID_SUCCESS](
    state: SupportModuleState,
    payload: ScheduleResponse
  ): void {
    state.monitoringSchedule = payload;
  },

  /*--------------------
    SALES DASHBOARD REPORT
  ----------------------*/
  //GET SALES TEAM DASHBOARD REPORT
  [SupportMutationTypes.GET_SALES_TEAM_DASHBOARD_REPORT_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_SALES_TEAM_DASHBOARD_REPORT_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_SALES_TEAM_DASHBOARD_REPORT_SUCCESS](
    state: SupportModuleState,
    payload: SalesTeamDashboardReport
  ): void {
    state.status = Status.SUCCESS;
    state.salesTeamDashboardReport = payload;
  },

  //GET SALES TEAM DASHBOARD REPORT
  [SupportMutationTypes.GET_CORDINATOR_DASHBOARD_REPORT_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_CORDINATOR_DASHBOARD_REPORT_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_CORDINATOR_DASHBOARD_REPORT_SUCCESS](
    state: SupportModuleState,
    payload: CordinatorDashboardReport
  ): void {
    state.status = Status.SUCCESS;
    state.cordinatorDashboardReport = payload;
  },

  // GET RECENT SALES REFERAL
  [SupportMutationTypes.GET_SALES_TEAM_RECENT_REFERAL_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_SALES_TEAM_RECENT_REFERAL_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_SALES_TEAM_RECENT_REFERAL_SUCCESS](
    state: SupportModuleState,
    payload: Array<SalesReferal>
  ): void {
    state.status = Status.SUCCESS;
    state.salesReferal = payload;
  },

  // GET ALL REFERALS
  [SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_SUCCESS](
    state: SupportModuleState,
    payload: Array<SalesReferal>
  ): void {
    state.status = Status.SUCCESS;
    state.salesReferal = payload;
  },

  // GET SALES DETAILS BY CUSTOMER ID
  [SupportMutationTypes.GET_SALES_DETAILS_BY_CUSTOMER_ID_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_SALES_DETAILS_BY_CUSTOMER_ID_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_SALES_DETAILS_BY_CUSTOMER_ID_SUCCESS](
    state: SupportModuleState,
    payload: SalesBreakdown
  ): void {
    state.status = Status.SUCCESS;
    state.salesBreakdown = payload;
  },

  // GET ALL SALES AGENT QUOTES
  [SupportMutationTypes.GET_SALES_ALL_QUOTES_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_SALES_ALL_QUOTES_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_SALES_ALL_QUOTES_SUCCESS](
    state: SupportModuleState,
    payload: Array<QuoteResponseRequest>
  ): void {
    state.status = Status.SUCCESS;
    state.salesAllQuotes = payload;
  },

  //GET CORDINATOR RECENT REFERRAL
  [SupportMutationTypes.GET_CORDINATOR_RECENT_REFERAL_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_CORDINATOR_RECENT_REFERAL_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_CORDINATOR_RECENT_REFERAL_SUCCESS](
    state: SupportModuleState,
    payload: Array<CordinatorReferredCustomerList>
  ): void {
    state.status = Status.SUCCESS;
    state.recentRefferdLists = payload;
  },

  //GET OTHER CORDINATOR REFERAALS
  [SupportMutationTypes.GET_CORDINATOR_OTHER_REFERAL_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_CORDINATOR_OTHER_REFERAL_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_CORDINATOR_OTHER_REFERAL_SUCCESS](
    state: SupportModuleState,
    payload: Array<CordinatorReferredCustomerList>
  ): void {
    state.status = Status.SUCCESS;
    state.cordinatorRefferdLists = payload;
  },

  //GET CORDINATOR REFERAL BY ID
  [SupportMutationTypes.GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_LOADING](
    state: SupportModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [SupportMutationTypes.GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_ERROR](
    state: SupportModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.supportError = error;
  },
  [SupportMutationTypes.GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_SUCCESS](
    state: SupportModuleState,
    payload: CordinatorReferredCustomerDetails
  ): void {
    state.status = Status.SUCCESS;
    state.cordinatorReferredDetails = payload;
  },
};
